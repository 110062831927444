import { Component, OnInit } from '@angular/core';
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-dialog-confirmation-page',
  templateUrl: './dialog-confirmation-page.component.html',
})
export class DialogConfirmationPageComponent implements OnInit {
  /**
   * @var data
   * it's variable for to data input
   */
  public data: any;

  /**
   * @var templateText
   * it's variable for to data input
   */
  public templateText: any;

  /**
   * @method ngOnInit  return void value
   * should init component and set data to element
   */
  ngOnInit(): void {
    this.templateText = this.data;
    this.data.closeEventDialog.subscribe(() => {
      this.data.submitEvent(1);
    });
  }

  /**
   * @method continueEvent return void value
   * should callback to emit continueEvent and recived param value.
   */
  continueEvent() {
    this.data.submitEvent(0);
    this.data.closeDialog();
  }
}
