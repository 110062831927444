import { Component, OnInit } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-universal-default-page',
  templateUrl: './universal-default-page.component.html',
})
export class UniversalDefaultPageComponent {
  /**
   * Content Extra info
   *
   * @type {any}
   *
   */
  public data: any;
}
