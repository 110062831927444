const appVersion = require('../../package.json').version;

export const environment = {
  production: true,
  apiUrl: '/sucursalvirtual/security-service',
  intranetBaasUrl: '/sucursalvirtual/intranet-baas',
  privacyUrl: 'assets',
  branchIoUrl: 'https://api2.branch.io/v2/event/custom',
  constanciasFeatures: true,
  expedientesFeatures: true,
  activeFeature: false,
  activeSDK: true,
  branchIoKey: 'key_test_jo2ufX9wYClwt0RZC8FQXcjoCyiN3wW1',
  cookieMobile: 'SV-COOKIE-NAME',
  appVersion: `PRE-${appVersion}`,
  cryptoKey: 'key',
  entorno: 'PRE',
  enableUpdateMyAddress: false,
};
