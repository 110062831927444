import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { TraceIdService } from 'src/app/core/services/traceId/trace-id.service';

@Injectable()
export class TraceIdInterceptor implements HttpInterceptor {
  constructor(private traceIdService: TraceIdService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const traceId = this.traceIdService.getTraceId();

    if (traceId) {
      request = request.clone({
        setHeaders: { 'X-B3-TraceId': traceId },
      });
    }

    return next.handle(request).pipe(
      tap((event: HttpEvent<unknown>) => {
        if (
          event instanceof HttpResponse &&
          event.headers.has('X-B3-TraceId')
        ) {
          const traceIdHeader = event.headers.get('X-B3-TraceId') ?? '';
          this.traceIdService.setTraceId(traceIdHeader);
        }
      })
    );
  }
}
