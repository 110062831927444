import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from './page/page.component';
import { ButtonComponent } from './components/button/button.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { LogoComponent } from './components/logo/logo.component';
import { SpanComponent } from './components/span/span.component';
import { TypographyComponent } from './components/typography/typography.component';
import { ImageComponent } from './components/image/image.component';
import { LinkComponent } from './components/link/link.component';
import { InputComponent } from './components/input/input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalComponent } from './components/modal/modal.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { HeaderComponent } from './components/header/header.component';
import { HintComponent } from './components/hint/hint.component';
import { CodeInputComponent } from './components/code-input/code-input.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ModalTemplateComponent } from './components/modal-template/modal-template.component';
import { ModalContentComponent } from './components/modal-content/modal-content.component';
import { ModalHeaderComponent } from './components/modal-header/modal-header.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { GenericModalComponent } from './components/generic-modal/generic-modal.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { SelectComponent } from './components/select/select.component';
import { FormatDateDirective } from './directives/format-date/format-date.directive';
import { HttpClientModule } from '@angular/common/http';
import { CardComponent } from './components/card/card.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { ToggleContentComponent } from './components/toggle-content/toggle-content.component';
import { RouterModule } from '@angular/router';
import { ItemComponent } from './components/item/item.component';
import { CustomInputComponent } from './components/custom-input/custom-input.component';
import { ToastComponent } from './components/toast/toast.component';
import { LinkListComponent } from './components/link-list/link-list.component';
import { SelectIOSModalComponent } from './components/select-ios-modal/select-ios-modal.component';
import { TitleComponent } from './components/title/title.component';
import { TypographyV2Component } from './components/typography-v2/typography-v2.component';
import { ClassHandlerPipe } from './pipes/class-handler.pipe';
import { SelectAccountModalComponent } from '../modules/constancia-fiscal/components/select-account-modal/select-account-modal.component';
import { SelectAccountComponent } from '../modules/constancia-fiscal/components/select-account/select-account.component';
import { ResultsSectionComponent } from '../modules/constancia-fiscal/components/results-section/results-section.component';
import { MaskPipe } from './pipes/mask/mask.pipe';
import { SelectIosComponent } from './components/select-ios/select-ios.component';
import { AccountProfilePipe } from '../modules/constancia-fiscal/pipes/account-profile/account-profile.pipe';
import { FlameCardModule } from '@ngx-mxflame/atoms/card';
import { TaxCertificateNamePipe } from './pipes/tax-certificate-name/tax-certificate-name.pipe';
import { SelectIosScrollDirective } from './directives/select-ios-scroll/select-ios-scroll.directive';

@NgModule({
  declarations: [
    PageComponent,
    LogoComponent,
    TypographyComponent,
    ButtonComponent,
    SpanComponent,
    CheckboxComponent,
    ImageComponent,
    LinkComponent,
    InputComponent,
    ModalComponent,
    ProgressBarComponent,
    HeaderComponent,
    HintComponent,
    CodeInputComponent,
    SelectAccountModalComponent,
    SelectAccountComponent,
    ResultsSectionComponent,
    LoaderComponent,
    ModalTemplateComponent,
    ModalContentComponent,
    ModalHeaderComponent,
    PrivacyComponent,
    GenericModalComponent,
    SpinnerComponent,
    RadioButtonComponent,
    CardComponent,
    SelectComponent,
    FormatDateDirective,
    TooltipComponent,
    ToggleContentComponent,
    ItemComponent,
    CustomInputComponent,
    ToastComponent,
    LinkListComponent,
    SelectComponent,
    SelectIOSModalComponent,
    TypographyV2Component,
    ToastComponent,
    TitleComponent,
    ClassHandlerPipe,
    MaskPipe,
    AccountProfilePipe,
    SelectIosComponent,
    TaxCertificateNamePipe,
    SelectIosScrollDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    FlameCardModule,
  ],
  exports: [
    PageComponent,
    SelectIOSModalComponent,
    LogoComponent,
    TypographyComponent,
    ButtonComponent,
    SpanComponent,
    CheckboxComponent,
    ImageComponent,
    LinkComponent,
    InputComponent,
    ModalComponent,
    ProgressBarComponent,
    HeaderComponent,
    HintComponent,
    CodeInputComponent,
    LoaderComponent,
    ModalTemplateComponent,
    ModalContentComponent,
    ModalHeaderComponent,
    PrivacyComponent,
    GenericModalComponent,
    RadioButtonComponent,
    CardComponent,
    SelectComponent,
    TooltipComponent,
    ToggleContentComponent,
    ItemComponent,
    CustomInputComponent,
    ToastComponent,
    LinkListComponent,
    TypographyV2Component,
    TitleComponent,
    SelectAccountModalComponent,
    SelectAccountComponent,
    ResultsSectionComponent,
    MaskPipe,
    AccountProfilePipe,
    SelectIosComponent,
  ],
})
export class SharedModule {}
