import { Injectable } from '@angular/core';
import { isMobile } from '../../helpers/utils/shared/mobile-helper';
import { gtm } from '../../helpers/utils/shared';

@Injectable({
  providedIn: 'root',
})
export class DigitalBranchService {
  private isBillEnabled = false;
  private isTaxCertificateEnabled = true;
  private isFaceIdEnabled = false;
  private isValidVersion = false;
  private validVersion = 2024010101;
  private versionAppName: string = gtm.versionAppName;

  getBillStatus = () => (isMobile() ? this.isBillEnabled : true);
  setBillStatus = (status: boolean) => {
    this.isBillEnabled = status;
  };

  getTaxCertificateStatus = () =>
    isMobile() ? this.isTaxCertificateEnabled : true;
  setTaxCertificateStatus = (status: boolean) => {
    this.isTaxCertificateEnabled = status;
  };

  getFeatureFlag = (featureFlag: string) => {
    switch (featureFlag) {
      case 'constanciasFeatures':
        return this.getTaxCertificateStatus();
      case 'expedientesFeatures':
        return this.getBillStatus();
      default:
        return false;
    }
  };

  getFaceIdFlag = () => this.isFaceIdEnabled;
  setFaceIdFlag = (status: boolean) => {
    this.isFaceIdEnabled = status && this.isValidVersion;
  };

  getIsValidVersion = () => this.isValidVersion;
  setIsValidVersion = (version: string) => {
    this.isValidVersion = Number(version) >= this.validVersion;
  };

  getVersionAppName = () => this.versionAppName;
  setVersionAppName = (versionAppName: string) => {
    this.versionAppName = versionAppName || this.versionAppName;
  };
}
