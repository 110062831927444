export const addTime = (time: Date, minutes: number, seconds: number) => {
  time.setTime(time.getTime() + minutes * 60000);
  return time.setSeconds(time.getSeconds() + seconds);
};

export const convertDifferenceToMinutes = (diff: number) =>
  Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

export const convertDifferenceToSeconds = (diff: number) =>
  Math.floor((diff % (1000 * 60)) / 1000);

export const formatToTwoDigitsTime = (time: number) =>
  time < 10 ? `0${time}` : time;

export const getCountdownString = (difference: number) =>
  difference < 0
    ? ''
    : `${formatToTwoDigitsTime(
        convertDifferenceToMinutes(difference)
      )}:${formatToTwoDigitsTime(
        convertDifferenceToSeconds(difference)
      )} minutos`;

export const getYear = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  return year;
};
