import { ICardProfile } from 'src/app/modules/constancia-fiscal/models';
import { accountsProfile } from './constants';

export const accountProfileByNumber = (accountNumber: string) => {
  const localData: any = accountsProfile;
  const defaultProfile: any = localData.default || {};
  if (!accountNumber) {
    return defaultProfile;
  }
  const profiles: ICardProfile[] = localData?.profiles || [];
  const accountProfile = profiles.find((profile: ICardProfile) =>
    accountNumber.endsWith(profile.endingPattern)
  );
  return accountProfile || defaultProfile;
};
