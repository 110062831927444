import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, first } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SsoTokenService {
  public tokenValidatetUrl = `${environment.apiUrl}/v1/dullToken/validate`;
  private options = {
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-Use-Env': 'PRE',
      'X-Use-Proxy': 'true',
      MsgRqHdr: '{"ClientIPAddress": "180.186.248.158"}',
    },
  };

  constructor(private http: HttpClient) {}

  validateToken(ssoToken: string): Observable<any> {
    const tokenValidatePayload = {
      SecObjSel: {
        SecObjValue: ssoToken,
      },
    };
    return this.http
      .post(this.tokenValidatetUrl, tokenValidatePayload, this.options)
      .pipe(first());
  }
}
