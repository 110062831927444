import {
  digitalBranchMock,
  faceId,
  fingerprints,
  ocr,
  rsa,
  telmexMock,
  versionInfoMock,
} from '../../utils/shared/constants';

export const setINEData = () => ({
  event: 'OCR',
  message: ocr,
  mockData: true,
});

export const setDeviceInfo = () => ({
  event: 'RSA',
  message: rsa,
  mockData: true,
});

export const setFingerprintsData = () => ({
  event: 'FINGERPRINT',
  message: fingerprints,
  mockData: true,
});
export const setFaceIdentificationData = () => ({
  event: 'FACE',
  message: faceId,
  mockData: true,
});

export const setAccessCode = () => ({
  event: 'ACCESS_TKN',
  message:
    '{"clientId":"4LQNAPRVMEZUPHPNIBG55Z-9AKLRRMUKHLUPAQIGUMK","access_code":"Bearer PFJPFahUXgs4cPymIRjTJIteZo8KVg59PCAgDqirKt8qbfY0="}',
  mockData: true,
});

export const setSuperToken = () => ({
  event: 'SUPER_TOKEN',
  message: '{"typeOTP":"CT","token":"12345678", "codeVerifier": ""}',
  mockData: true,
});
export const setSaveCompressedDocs = () => ({
  event: 'saveCompressedDocs',
  message: 'SUCCESS',
  mockData: true,
});

export const setDigitalBranch = () => ({
  event: 'fetchDigitalBranchConfiguration',
  message: digitalBranchMock,
  mockData: true,
});

export const setVersionCodeData = () => ({
  event: 'VERSIONINFO',
  message: versionInfoMock,
  mockData: true,
});

export const mockProofOfResidency = () => ({
  event: 'POA',
  message: telmexMock,
  mockData: true,
});

export const mockObtainDeviceVersion = () => ({
  event: 'DEVICE_VERSION',
  message: 34,
  mockData: true,
});
