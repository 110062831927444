import { copies } from 'src/app/shared/helpers/utils/shared';
import { ItemContent } from '../types';

export const listItems: ItemContent[] = [
  {
    imageTitle: 'wifi',
    imageSource: 'assets/sv-wifi.png',
    text: copies.antesDeComenzar.internet,
  },
  {
    imageTitle: 'id',
    imageSource: 'assets/sv-id.png',
    text: copies.antesDeComenzar.ine,
  },
  {
    imageTitle: 'location',
    imageSource: 'assets/icon-pin.svg',
    text: copies.antesDeComenzar.permisos,
  },
];
