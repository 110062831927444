import { ErrorType } from './../../../shared/models/errors/types';
import { gtm } from 'src/app/shared/helpers/utils/shared';
import { Injectable } from '@angular/core';
import { IGtmData } from 'src/app/shared/models/interfaces';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { DigitalBranchService } from 'src/app/shared/services/digital-branch/digital-branch.service';

declare const window: Window &
  typeof globalThis & {
    dataLayer: any[];
  };

@Injectable({
  providedIn: 'root',
})
export class GtmService {
  private apiBranchIoUrl = `${environment.branchIoUrl}`;
  private branchIoData!: any;
  private deviceInfo: any;
  private versionApp = sessionStorage.getItem('VERSIONINFO');

  constructor(
    private http: HttpClient,
    private digitalBranchService: DigitalBranchService
  ) {}

  private getTitulo(pagina: string | undefined) {
    return '|' + pagina?.replace(/\//g, '|') + '|';
  }

  private getScreenDimensions(screenSize: string, side: string) {
    if (side === 'width') {
      return parseInt(screenSize.split('x')[0]);
    } else {
      return parseInt(screenSize.split('x')[1]);
    }
  }

  getGtmPage(url: string) {
    switch (url) {
      case 'escanea-tu-ine':
        return gtm.page.escaneaTuIne;
      case 'identificacion':
        return gtm.page.identificacion;
      case 'actualizar':
        return gtm.page.actualizar;
      case 'captura-tus-huellas':
        return gtm.page.capturaTusHuellas;
      case 'captura-de-rostro':
        return gtm.page.capturaRostro;
      case 'comprobante':
        return gtm.page.comprobante;
      case 'consultar':
      case 'constancia-fiscal':
        return gtm.page.constancias;
      case undefined:
        return gtm.page.consulta;
      case 'mi-expediente':
        return gtm.page.expediente;
      case 'actualiza-direccion':
        return gtm.page.domicilioRegistrado;
      case 'comprobante-info':
        return gtm.page.cargaComprobante;
      case 'selecciona-comprobante':
        return gtm.page.seleccionComprobante;
      case 'carga-comprobante':
        return gtm.page.seleccionComprobante;
      case 'comprobante-recibo':
        return gtm.page.comprobanteCargado;
      default:
        return gtm.page.bienvenido;
    }
  }

  getCurrentUrl(eventUrl: string) {
    const actualRoute = eventUrl.split('/')[2];
    const secondSubarray = this.getGtmPage(actualRoute);
    return secondSubarray;
  }

  pushEvent(eventType: string, options?: any) {
    const gtmPageView = gtm.event.page;
    const gtmInteraction = gtm.event.interaction;
    this.versionApp = sessionStorage.getItem('VERSIONINFO') ?? 'unknow';

    let gtmData: IGtmData;

    switch (eventType) {
      case gtmPageView:
        gtmData = {
          url: options?.page,
          titulo: this.getTitulo(options?.page),
          ...this.deviceInfo,
          ...gtm.pageView,
          entorno: environment.entorno,
          event: gtmPageView,
          versionApp:
            this.versionApp || this.digitalBranchService.getVersionAppName(),
        };

        if (options.flujo) {
          gtmData['flujo'] = options.flujo;
        }
        window.dataLayer.push(gtmData);
        break;

      case gtmInteraction:
        gtmData = {
          gaEventCategory: options?.category,
          gaEventAction: options?.action,
          gaEventLabel: options?.label,
          entorno: environment.entorno,
          event: gtmInteraction,
          versionApp:
            this.versionApp || this.digitalBranchService.getVersionAppName(),
        };

        if (options.flujo) {
          gtmData['flujo'] = options.flujo;
        }
        window.dataLayer.push(gtmData);
        break;
    }
  }

  setDeviceInfo(brand: string, os: string) {
    const deviceInfoGTM = {
      marca_dispositivo: brand,
      sistema_operativo: os,
      tipoDispositivo: 'Movil',
    };
    this.deviceInfo = deviceInfoGTM;
  }

  setBranchIoData(
    deviceInfo: any,
    options = {
      name: 'sucursal-virtual',
      event_alias: 'sucursalvirtual/comprobante',
    }
  ) {
    const isAndroid = deviceInfo.DeviceSystemName.toUpperCase() === 'ANDROID';
    const isIOS = deviceInfo.DeviceSystemName.toUpperCase() === 'IOS';

    const branchIoData = {
      name: options.name,
      customer_event_alias: options.event_alias,
      user_data: {
        advertising_ids: {
          oaid: deviceInfo.AdvertiserId,
        },
        os: deviceInfo.DeviceSystemName,
        os_version: deviceInfo.DeviceSystemVersion,
        environment: 'FULL_APP',
        aaid: isAndroid ? deviceInfo.HardwareID : '',
        idfa: isIOS ? deviceInfo.HardwareID : '',
        android_id: isAndroid ? deviceInfo['OS_ID'] : '',
        limit_ad_tracking: false,
        developer_identity: '',
        country: 'MX',
        language: 'ES',
        ip: deviceInfo.WiFiNetworksData?.BBSID || '',
        local_ip: '',
        brand: deviceInfo.DeviceName,
        app_version: '1.0.0',
        model: deviceInfo.DeviceModel,
        screen_dpi: 2,
        screen_height: this.getScreenDimensions(
          deviceInfo.ScreenSize,
          'height'
        ),
        screen_width: this.getScreenDimensions(deviceInfo.ScreenSize, 'width'),
      },
      custom_data: {
        userid: '',
      },
      metadata: {},
      branch_key: environment.branchIoKey,
    };
    this.branchIoData = branchIoData;
    this.setDeviceInfo(deviceInfo.DeviceModel, deviceInfo.DeviceSystemName);
  }

  updateUserId(userid: string) {
    if (this.branchIoData && this.branchIoData['custom_data']) {
      this.branchIoData['custom_data']['userid'] = userid;
    }
  }

  getBranchIoData() {
    return this.branchIoData;
  }

  sendBranchIoData() {
    this.http.post(this.apiBranchIoUrl, this.branchIoData).subscribe(() => {});
  }

  getErrorType(errorType: any) {
    switch (errorType.error) {
      case ErrorType.NoCliente:
        return gtm.page.error.noCliente;
      case ErrorType.Mayor65:
        return gtm.page.error.mayor65;
      case ErrorType.IntentosExcedidosIdentificacion:
        return gtm.page.error.intentosExcedidos;
      case ErrorType.IntentosExcedidosOtp:
        return gtm.page.error.intentosExcedidos;
      case ErrorType.Monoproducto:
        return gtm.page.error.monoproducto;
      case ErrorType.VolverAIntentar:
        return gtm.page.error.retry;
      case ErrorType.Bloqueado24Hrs:
        return gtm.page.error.bloqueo;
      case ErrorType.BloqueoSeguridad:
        return gtm.page.error.bloqueo;
      case ErrorType.BloqueoRsa:
        return gtm.page.error.rsaDeny;
      case ErrorType.Generico:
        return gtm.page.error.generico;
      default:
        return gtm.page.error.generico;
    }
  }
}
