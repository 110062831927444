export enum ErrorType {
  Mayor65,
  IntentosExcedidosIdentificacion,
  VolverAIntentar,
  Monoproducto,
  IntentosExcedidosOtp,
  Generico,
  Bloqueado24Hrs,
  NoCliente,
  BloqueoSeguridad,
  BloqueoRsa,
}
