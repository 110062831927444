import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { RsaService } from '../../services/rsa/rsa.service';
const { v4: uuidv4 } = require('uuid');

@Injectable()
export class FingerprintInterceptor implements HttpInterceptor {
  constructor(
    private rsaService: RsaService //private traceIdService: TraceIdService, //private authService: AuthService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const deviceId = this.rsaService.getDeviceId ?? '';
    //const traceId = this.traceIdService.getTraceId();
    //const token = this.authService.getAccessToken();

    const products = '/customers/products';
    const withholdingsCertificates =
      '/withholdingsCertificates/financialYears/';

    if (
      request.url.includes(withholdingsCertificates) ||
      request.url.includes(products)
    ) {
      request = request.clone({
        setHeaders: {
          'X-Device-Fingerprint': deviceId,
          'x-santander-session-id': uuidv4(),
        },
      });
      return next.handle(request);
    }

    return next.handle(request);
  }
}
