import { IBiometricData, IINEData } from 'src/app/shared/models/interfaces';
export interface IIdentificationRequest {
  name: string;
  paternalName: string;
  maternalName: string;
  birthDate: string;
  curp: string;
}

export interface IIdentificationData {
  partyId: string;
  status: boolean;
  levelAccount: boolean;
  validAge: boolean;
}

export interface IDataDetails {
  partyId: string;
  name: string;
  paternalName: string;
  maternalName: string;
  curp: string;
  identificationCardData: Partial<IINEData>;
}
export interface IContactDetails {
  newMailAddress: string;
  newNumberPhone: string;
  carrier: string;
}

export interface IAuthorizationData {
  phoneNumber: string;
  emailAddress: string;
  modificationDateTime: string;
  reference: string;
}

export interface IJourneyBackData {
  date: string;
  riskResult: string;
  sessionId: string;
  state: string;
  functionalityId: string;
  functionalityDescription: string;
}

export interface INotificationData {
  level: UpdateServiceResponse;
  code: NotificationCode;
  message: string;
  timestamp: string;
}

export interface IUpdateProfileServiceResponse<Type> {
  data?: Type;
  notifications: INotificationData[];
}

export interface AuthenticationRequest {
  dataDetails: IDataDetails;
  contactDetails: IContactDetails;
  biometricDetails: IBiometricData;
}

export type UserProfile = AuthenticationRequest;

export interface AuthenticationResponse {
  phoneNumber: string;
  emailAddress: string;
  modificationDateTime: string;
  reference: string;
}

export interface Comprobante {
  modificationDateTime: string;
  reference: string;
  emailAddress: string;
  phoneNumber: string;
  status?: string;
}

export interface IOTPRequest {
  name: string;
  lastName: string;
  numberPhone: string;
  emailAddress: string;
  medium: string;
}

export interface IOTPResponse {
  key: string;
  otpExpirationDate: string;
}

export interface IValidateRequest {
  key: string;
  otp: string;
}

export enum UpdateServiceMethod {
  IDENTIFICATION,
  AUTHORIZATION,
  OTP_REQUEST,
  OTP_VALIDATE,
}

export enum UpdateServiceResponse {
  INFO = 'INFO',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}

export enum NotificationCode {
  UISSUCCESS = 'UISSUCCESS',
  UISWARNING = 'UISWARNING',
  UISFAIL = 'UISFAIL',
  GOTPSUCCESS = 'GOTPSUCCESS',
  GOTPFAIL = 'GOTPFAIL',
  VOTPSUCCESS = 'VOTPSUCCESS',
  VOTPFAIL = 'VOTPFAIL',
  BIOFAIL = 'BIOFAIL',
  UUDSUCCESS = 'UUDSUCCESS',
  UUDFAIL = 'UUDFAIL',
}

export enum ResidencyDocumentType {
  'CFE' = 0,
  'TELMEX' = 1,
  'OTHER' = 2,
}

/**
 * Salir: Cuando el usuario presiona el boton nativo back dentro del sdk de biometricos
 * ERROR_CAPTURA: Cuando el sdk tiene un fallo controlado durante la captura
 * ERROR: Error no controlado durante la captura
 */
export enum BiometricsErrors {
  SALIR = 'SALIR',
  ERROR_CAPTURA = 'ERROR_CAPTURA',
  ERROR = 'ERROR',
  Salir = 'Salir',
  Error = 'Error',
}

/**
 * C-000: Error no controlado en el sdk
 * C-001: Error al capturar datos biometricos del sdk
 * C-002: Error en las notificiaciones de la solicitud, codigo UUDFAIL
 * C-003: Error en la respuesta
 * C-004: Error no mapeado
 * C-005: Error en la solicitud HTTP
 */
export enum ErrorCodes {
  SDK_BIOMETRIC = 'C-000',
  SDK_BIOMETRIC_CAPTURE = 'C-001',
  CONTACT_UUDFAIL = 'C-002',
  CONTACT_RESPONSE_FAIL = 'C-003',
  CONTACT_REQUEST_FAIL = 'C-004',
  REQUEST_FAIL = 'C-005',
  SDK_BIOMETRIC_FAIL = 'C-006',
}
