import { createReducer, on } from '@ngrx/store';
import * as UiActions from '../actions/ui.actions';
import { IUiState } from '../models/interfaces';

const initialState: IUiState = {
  loader: false,
  loaderMessage: '',
  focus: false,
  modalOpen: false,
};

export const uiReducer = createReducer<IUiState>(
  initialState,
  on(
    UiActions.showLoader,
    (state, { loaderMessage }): IUiState => ({
      ...state,
      loader: true,
      loaderMessage: loaderMessage,
    })
  ),
  on(
    UiActions.hideLoader,
    (state): IUiState => ({
      ...state,
      loader: false,
      loaderMessage: '',
    })
  )
);
