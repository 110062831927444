import { scrollHeight } from './constants';

export const isValidAndroidView = () => navigator.userAgent.includes('Android');
export const scrollWindow = (coordinateY: number) => {
  window.scrollTo({ top: coordinateY, behavior: 'smooth' });
};
export const isHiddenByKeyboard = (coordinateY: number) =>
  coordinateY > scrollHeight * 0.6;

export function isMobile() {
  return (
    typeof window.Connect !== 'undefined' ||
    typeof window.webkit !== 'undefined'
  );
}

export function getMobileOperatingSystem() {
  if (!isMobile()) {
    return 'Not a mobile device';
  }

  const userAgent = navigator.userAgent || navigator.vendor;

  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'Unknown mobile device';
}
