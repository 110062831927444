import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IAppConfig } from '../models/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  private configurationServiceURL = `${environment.apiUrl}/v1/transmit-wrapper-config`;

  appConfig = {};

  constructor(private http: HttpClient) {}
  setAppConfig(): any {
    this.http
      .get<IAppConfig>(this.configurationServiceURL)
      .subscribe(appConfigResponse => {
        this.appConfig = appConfigResponse;
      });
  }

  getAppConfig(): any {
    return this.appConfig;
  }
}
