import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RsaInterceptor } from './core/interceptors/rsa/rsa.interceptor';
import { TraceIdInterceptor } from './core/interceptors/trace-id/trace-id.interceptor';

import {
  FlameTheme,
  FlameThemeService,
  FlameThemeModule,
} from '@ngx-mxflame/atoms/theme';

import { TransmitModule } from './transmit/transmit.module';

//NGRX
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { appReducers } from './app.reducer';
import { environment } from 'src/environments/environment';
import { FingerprintInterceptor } from './core/interceptors/fingerprint/fingerprint.interceptor';

@NgModule({
  declarations: [AppComponent],
  imports: [
    FlameThemeModule.forRoot({
      themes: [FlameTheme],
      default: FlameTheme,
    }),
    AppRoutingModule,
    BrowserModule,
    SharedModule,
    TransmitModule,
    StoreModule.forRoot(appReducers),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      autoPause: true,
      logOnly: environment.production,
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: RsaInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FingerprintInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: TraceIdInterceptor, multi: true },
    FlameThemeService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
