import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  IInputsConfiguration,
  IInputValidations,
} from '../../../models/interfaces';
import { getBirthDateFromCurp } from './string-helper';

export const buildValidationsForm = (
  inputs: IInputsConfiguration[],
  screenForm: FormGroup,
  originalValues?: any,
  clearValidations: boolean = false
) => {
  if (clearValidations) {
    const controlNames = Object.keys(screenForm.controls);
    // Itera a través de los nombres de los controles y limpia las validaciones
    controlNames.forEach(controlName => {
      screenForm.get(controlName)?.clearValidators();
      screenForm.get(controlName)?.updateValueAndValidity();
    });
  }
  for (let input of inputs) {
    let formBuilder = new FormBuilder();
    const validations = [];
    if (input.inputType === 'email') {
      validations.push(Validators.email);
    }
    const inputValidations: IInputValidations = input.validations;
    if (inputValidations) {
      if (inputValidations.required) {
        validations.push(Validators.required);
      }
      if (inputValidations.minLength) {
        validations.push(Validators.minLength(inputValidations.minLength));
      }
      if (inputValidations.maxLength) {
        validations.push(Validators.maxLength(inputValidations.maxLength));
      }
      if (inputValidations.pattern) {
        validations.push(Validators.pattern(inputValidations.pattern));
      }
      if (inputValidations.shouldConfirm && inputValidations.confirmWith) {
        validations.push(
          confirmationValidator(input.field, inputValidations.confirmWith)
        );
      }
      if (originalValues && inputValidations.confirmDOB) {
        validations.push(validateDateOfBirth(inputValidations.confirmDOB));
      }
      if (originalValues && inputValidations.confirmDOB2) {
        validations.push(validateDateOfBirth2(inputValidations.confirmDOB2));
      }
      if (originalValues && inputValidations.percentage) {
        validations.push(
          percentageChangeValidator(
            originalValues[input.field],
            parseInt(inputValidations.percentage)
          )
        );
      }
    }
    if (originalValues) {
      screenForm.controls[input.field].clearValidators;
      screenForm.controls[input.field].addValidators(validations);
    }
    screenForm.addControl(
      input.field,
      formBuilder.control('', Validators.compose(validations))
    );
  }
};

const confirmationValidator =
  (originalInput: string, confirmWith: string): any =>
  (control: AbstractControl) => {
    const root = control.root;
    if (root.get(confirmWith)?.pristine) {
      return null;
    }
    const originalValue = root.get(originalInput)?.value;
    const confirmationValue = root.get(confirmWith)?.value;

    if (originalValue !== confirmationValue) {
      return { notMatched: true };
    }

    return null;
  };

export const assignAndValidate = (
  inputName: string,
  value: string,
  screenForm: FormGroup,
  validationMessages: any
) => {
  screenForm.controls[inputName].setValue(value);
  screenForm.controls[inputName].updateValueAndValidity();

  const inputEl = validationMessages.find(
    (validation: any) => validation.field === inputName
  );

  if (inputEl) {
    if (!inputEl.patternHas) {
      inputEl.empty = false;
      setTimeout(() => {
        inputEl.valid = screenForm.controls[inputName].valid;
      });
    } else {
      const hasUppercase = /[A-Z]/.test(value);
      const hasNumber = /\d/.test(value);
      const hasMinLength = value.length >= 8;
      const hasSpecialCharacter = /[^A-Za-z0-9]/.test(value);
      validationMessages.forEach((hint: any) => {
        hint.empty = false;
        if (hint.patternHas.includes('uppercase')) {
          hint.valid = hasUppercase;
        }
        if (hint.patternHas.includes('number')) {
          hint.valid = hasNumber;
        }
        if (hint.patternHas.includes('hasMinLength')) {
          hint.valid = hasMinLength;
        }
        if (hint.patternHas.includes('specialCharacter')) {
          hint.valid = !hasSpecialCharacter;
        }
      });
    }
  }
};

// Función de validación personalizada
const percentageChangeValidator =
  (originalValue: string, percentage: number): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    const currentValue = control.value;
    const allowedDifference = (originalValue.length * percentage) / 100;
    // Calcular la diferencia entre los valores original y actual
    const difference = levenshteinDistance(originalValue, currentValue);
    //Si la lectura regresó un string vacio, dejarlo pasar o si el porcentaje es 100
    if (originalValue.length === 0 || percentage == 100) {
      return null;
    }
    // Verificar si la diferencia está dentro del margen permitido
    if (difference <= allowedDifference) {
      return null; // La validación pasa, no hay cambios significativos
    } else {
      return { percentageChange: true }; // La validación falla, hay cambios significativos
    }
  };
// Función para calcular la distancia de Levenshtein entre dos cadenas
const levenshteinDistance = (a: string, b: string): number => {
  const m = a.length;
  const n = b.length;
  const d: number[][] = [];
  for (let i = 0; i <= m; i++) {
    d[i] = [];
    d[i][0] = i;
  }
  for (let j = 0; j <= n; j++) {
    d[0][j] = j;
  }
  for (let j = 1; j <= n; j++) {
    for (let i = 1; i <= m; i++) {
      if (a[i - 1] === b[j - 1]) {
        d[i][j] = d[i - 1][j - 1];
      } else {
        d[i][j] = Math.min(
          d[i - 1][j] + 1, // Deletions
          d[i][j - 1] + 1, // Insertions
          d[i - 1][j - 1] + 1 // Substitutions
        );
      }
    }
  }
  return d[m][n];
};

const validateDateOfBirth =
  (curpInput: string): any =>
  (control: AbstractControl) => {
    const root = control.root;
    const curpControl = root.get(curpInput);
    if (curpControl && curpControl.value) {
      const curp = curpControl.value.toUpperCase();
      const dateOfBirth = control.value; // Obtener el valor de la fecha de nacimiento del control
      // Formatear la fecha de nacimiento ingresada al formato del CURP para compararlas
      const formattedDateOfBirth = getBirthDateFromCurp(curp.substring(4, 10));
      // Comparar las fechas de nacimiento
      if (dateOfBirth !== formattedDateOfBirth) {
        return { dateOfBirthMismatch: true };
      }
    }
    return null;
  };

const validateDateOfBirth2 =
  (DOBInput: string): any =>
  (control: AbstractControl) => {
    const root = control.root;
    const dobControl = root.get(DOBInput);
    if (dobControl && dobControl.value) {
      const dob = dobControl.value.toUpperCase();
      const curp = control.value; // Obtener el valor de la fecha de nacimiento del control
      // Formatear la fecha de nacimiento ingresada al formato del CURP para compararlas
      const formattedDateOfBirth = getBirthDateFromCurp(curp.substring(4, 10));
      // Comparar las fechas de nacimiento
      if (dob !== formattedDateOfBirth.toLocaleUpperCase()) {
        return { dateOfBirthMismatch: true };
      }
    }
    return null;
  };

export const validateObject = (obj: any): boolean => {
  if (!obj) {
    return false; // Object is null or undefined
  }

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (
        (typeof value === 'string' && value.trim() === '') ||
        value === null
      ) {
        return false; // Empty string or null value found
      }
    }
  }

  return true; // All properties are non-empty strings or non-null values
};
