import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SsoTokenService } from '../sso-token/sso-token.service';
import {
  getMobileOperatingSystem,
  isMobile,
} from '../../helpers/utils/shared/mobile-helper';
import {
  setAccessCode,
  setDeviceInfo,
  setDigitalBranch,
  setFaceIdentificationData,
  setFingerprintsData,
  setINEData,
  setSuperToken,
  setSaveCompressedDocs,
  setVersionCodeData,
  mockProofOfResidency,
  mockObtainDeviceVersion,
} from '../../helpers/utils/mocks/device-communication.mock';
import { ResidencyDocumentType } from 'src/app/modules/update-profile/models';

@Injectable({
  providedIn: 'root',
})
export class DeviceCommunicationService {
  private responseSubject: Subject<any> = new Subject<any>();
  private backendResponseSubject: Subject<any> = new Subject<any>();
  isActiveSDK = environment.activeSDK;
  private tokenSubscription!: Subscription;

  constructor(private ssoTokenService: SsoTokenService) {
    window.handleCallback = (event: string, message: any) => {
      this.responseSubject.next({ event: event, message: message });
    };
  }

  sendMessageToNative(event: string, message: any): void {
    switch (event) {
      case 'downloadPDF':
        if (this.isActiveSDK && isMobile()) {
          window.Connect.llamarPDF(message?.data, message?.fileName);
        }
        break;
      case 'SSOToken':
        if (isMobile()) {
          const SSOToken: string = window.Connect.getSSOToken();
          if (SSOToken) {
            this.validateSSOToken(SSOToken);
          }
        } else {
          const SSOToken: string =
            'hVpC5nnXLmWaoM7Xvr5ZDgmozuSzNuP0QKRjfMkWmySbv+gX6xbUEHJWGtDN5yBJaN9DdcYRNexovKFZy3RPkzAL4rMauK3fMx49DYqQEOWmsHnC4yfq2Zyv+i+BJYnlF88B53LuaYTfvM3tbzac0cJhcrWCMRKPeo3PqPOHLRpinYvwm2kHhLwiEt/72PLNd/qOVPMC882FJnpkqkbifUMke31KcrexkChplUvPjAi0bzWvOXOoI1w5L8vWjA2WdmHuI5Kk196Wq5xmcGcLOnCPV+nLJyKCb0O31nEWbyNAIGHJDT1FhUpa7werfwo8DdsbKKISaLv67ui1gNvZKA==';
          this.validateSSOToken(SSOToken);
        }
        break;
      case 'obtainFaceIdentification':
        if (this.isActiveSDK && isMobile()) {
          window.Connect.obtainFaceIdentification();
        } else {
          setTimeout(() => {
            this.responseSubject.next(setFaceIdentificationData());
          }, 1000);
        }
        break;
      case 'superToken':
        if (isMobile()) {
          if (getMobileOperatingSystem() === 'Android') {
            window.TokenManager.getToken().then(
              response =>
                this.responseSubject.next({
                  event: 'SUPER_TOKEN',
                  message: response,
                }),
              err => {
                //handle error
              }
            );
          } else {
            window.TokenManager.getToken();
          }
        } else {
          setTimeout(() => {
            this.responseSubject.next(setSuperToken());
          }, 1000);
        }
        break;
      case 'AccessCode':
        if (isMobile()) {
          window.Connect.getAccessCode();
        } else {
          setTimeout(() => {
            this.responseSubject.next(setAccessCode());
          }, 1000);
        }
        break;
      case 'obtainLocation':
        if (isMobile()) {
          window.Connect.obtainLocation();
        }
        break;
      case 'saveCompressedDocs':
        if (this.isActiveSDK && isMobile()) {
          window.Connect.saveCompressedDocs(message?.data, message?.fileName);
        } else {
          setTimeout(() => {
            this.responseSubject.next(setSaveCompressedDocs());
          }, 1000);
        }
        break;
      default:
        break;
    }
  }

  getProofOfResidency = (type: number) => {
    if (isMobile()) {
      if (type === ResidencyDocumentType.OTHER) {
        window.Connect.obtainOCRCapture();
      } else {
        window.Connect.obtainProofOfAddress(type);
      }
    } else {
      setTimeout(() => {
        this.responseSubject.next(mockProofOfResidency());
      }, 1000);
    }
  };

  getRSAConfig = () => {
    if (this.isActiveSDK && isMobile()) {
      window.Connect.obtainCollectData();
    } else {
      setTimeout(() => {
        this.responseSubject.next(setDeviceInfo());
      }, 1000);
    }
  };

  getDigitalBranchConfig = () => {
    if (
      this.isActiveSDK &&
      isMobile() &&
      window.Connect.fetchDigitalBranchConfiguration
    ) {
      window.Connect.fetchDigitalBranchConfiguration();
    } else {
      setTimeout(() => {
        this.responseSubject.next(setDigitalBranch());
      }, 1000);
    }
  };

  getOCR = () => {
    if (this.isActiveSDK && isMobile()) {
      window.Connect.obtainOCRCapture();
    } else {
      setTimeout(() => {
        this.responseSubject.next(setINEData());
      }, 1000);
    }
  };

  getFingerprints = () => {
    if (this.isActiveSDK && isMobile()) {
      window.Connect.obtainFingerprintCapture();
    } else {
      setTimeout(() => {
        this.responseSubject.next(setFingerprintsData());
      }, 1000);
    }
  };

  goToRoot = () => {
    window.Connect.goToRoot();
  };

  getVersionInfo = () => {
    if (isMobile() && window.Connect.getVersionInfo) {
      window.Connect.getVersionInfo();
    } else {
      setTimeout(() => {
        this.responseSubject.next(setVersionCodeData());
      }, 1000);
    }
  };

  /*
    Observable that we can subscribe to get callback responses
  */
  getResponse(): Observable<any> {
    return this.responseSubject.asObservable();
  }

  /*
    Observable that we can subscribe to get Backend responses
  */
  getBackendResponse(): Observable<any> {
    return this.backendResponseSubject.asObservable();
  }

  // Helper method to send message to iOS
  private postMessageIOS(
    handler: string,
    method: string,
    parameters: any[] | null,
    callback: string
  ) {
    const params = {
      name: method,
      parameters,
      callbackName: callback,
    };
    window.webkit.messageHandlers[handler].postMessage(JSON.stringify(params));
  }

  // Helper method to get token for Android
  private getTokenAndroid(manager: any, isSuper: boolean) {
    manager
      .getSuperToken(
        localStorage.getItem('codeChallenge') ?? '',
        'COME',
        'CONSULTA OPCION MI EXPEDIENTE',
        null
      )
      .then(
        (response: any) =>
          this.responseSubject.next({
            event: isSuper ? 'SUPER_TOKEN' : 'TOKEN',
            message: response,
          }),
        (err: any) => {
          //handle error
        }
      );
  }

  getSuperToken = () => {
    const deviceOS = getMobileOperatingSystem();

    if (deviceOS === 'Android') {
      const manager = window.Connect.isTransmitAllowed()
        ? window.TransmitTokenManager
        : window.TokenManager;
      this.getTokenAndroid(manager, window.Connect.isTransmitAllowed());
    } else if (deviceOS === 'iOS') {
      const isTransmitAllowed = JSON.parse(
        window.webkit.messageHandlers.Connect.postMessage(
          JSON.stringify({
            component: 'isTransmitAllowed',
            params: "'{action:isTransmitAllowed}'",
            callbackName: 'handleCallback',
          })
        )
      );
      if (isTransmitAllowed) {
        this.postMessageIOS(
          'Connect',
          'getSuperToken',
          [
            localStorage.getItem('codeChallenge') ?? '',
            'COME',
            'CONSULTA OPCION MI EXPEDIENTE',
            null,
          ],
          'handleCallback'
        );
      } else {
        this.postMessageIOS('Connect', 'getToken', null, 'handleCallback');
      }
    } else if (deviceOS === 'Not a mobile device') {
      setTimeout(() => {
        this.responseSubject.next(setSuperToken());
      }, 1000);
    } else {
      // handle error
    }
  };

  /*
  validate SSO TOKEN and gets sesion due this validation
  */
  private validateSSOToken(SSOToken: string): void {
    this.tokenSubscription = this.ssoTokenService
      .validateToken(SSOToken)
      .subscribe({
        next: (response: any) => {
          this.backendResponseSubject.next(response);
        },
        error: error => {
          //handle error
        },
      });
  }

  public obtainDeviceVersion(): void {
    if (isMobile()) {
      window.Connect.obtainDeviceVersion();
    } else {
      setTimeout(() => {
        this.responseSubject.next(mockObtainDeviceVersion());
      }, 100);
    }
  }
}
