import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-universal-default-template',
  templateUrl: './universal-default-template.component.html',
  styleUrls: ['./universal-default-template.component.scss'],
})
export class UniversalDefaultTemplateComponent implements OnInit {
  /**
   * emit event onContinue
   * @type Event
   */
  @Output() continueButtonEvent = new EventEmitter();

  /**
   * Content Extra info
   *
   * @type {any}
   *
   */
  @Input() transmitInformation: any;

  /**
   * labels
   *
   * @type {any}
   *
   */
  public data: any;

  public formData: any;

  /**
   * Inicialite component
   * @public
   * @return {*} void
   * ç@memberof UniversalNonModalTicketTemplateComponent
   */
  ngOnInit(): void {
    this.data = this.transmitInformation;
    // eslint-disable-next-line no-prototype-builtins
    if (this.transmitInformation.hasOwnProperty('form')) {
      this.formData = JSON.parse(this.transmitInformation.form);
      delete this.data['form'];
    }
  }
}
