import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TraceIdService {
  private traceId: string = '';

  constructor() {}

  setTraceId(traceId: string): void {
    this.traceId = traceId;
  }

  getTraceId(): string {
    return this.traceId;
  }
}
