import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlameButtonModule } from '@ngx-mxflame/atoms/button';
import { FlameIconModule } from '@ngx-mxflame/atoms/icon';
import { FlameRadioButtonModule } from '@ngx-mxflame/atoms/radio-button';
import { FlameDialogModule } from '@ngx-mxflame/atoms/dialog-web';
import { FlameSpinnerModule } from '@ngx-mxflame/atoms/spinner';
import { FlameInputModule } from '@ngx-mxflame/atoms/input';
import { FlameFormFieldModule } from '@ngx-mxflame/atoms/form-field';
import { FlameEmptyStateModule } from '@ngx-mxflame/atoms/empty-state';

import { DialogConfirmationPageComponent } from './pages/dialog-confirmation-page/dialog-confirmation-page.component';
import { DisplayInformationPageComponent } from './pages/display-information-page/display-information-page.component';
import { UniversalDefaultPageComponent } from './pages/universal-default-page/universal-default-page.component';
import { DisplayInformationTemplateComponent } from './templates/display-information-template/display-information-template.component';
import { LoaderTemplateComponent } from './templates/loader-template/loader-template.component';
import { UniversalDefaultTemplateComponent } from './templates/universal-default-template/universal-default-template.component';
import { RiskAssesstmentComponent } from './components/risk-assesstment/risk-assesstment.component';
import {
  ENV_CONFIG_AUTH,
  DYNAMIC_RENDER_SERVICE,
  IdentificationWrapperLibraryModule,
} from 'ngx-transmit-wrapper';
import { DynamicComponentRenderService } from './services/dynamic-component-render.service';
import { ConfigurationService } from './services/configuration.service';

// function appConfigLoadFactory(configurationService: ConfigurationService) {
//   return () => configurationService.getAppConfig().toPromise();
// }

@NgModule({
  declarations: [
    RiskAssesstmentComponent,
    DisplayInformationTemplateComponent,
    DisplayInformationPageComponent,
    LoaderTemplateComponent,
    DialogConfirmationPageComponent,
    UniversalDefaultPageComponent,
    UniversalDefaultTemplateComponent,
  ],
  imports: [
    CommonModule,
    FlameButtonModule,
    FlameIconModule,
    FlameRadioButtonModule,
    FlameDialogModule,
    FlameSpinnerModule,
    FlameInputModule,
    FlameFormFieldModule,
    FlameEmptyStateModule,
    IdentificationWrapperLibraryModule.forRoot(ENV_CONFIG_AUTH),
  ],
  exports: [
    RiskAssesstmentComponent,
    DisplayInformationTemplateComponent,
    DisplayInformationPageComponent,
    LoaderTemplateComponent,
    DialogConfirmationPageComponent,
    UniversalDefaultPageComponent,
    UniversalDefaultTemplateComponent,
  ],
  providers: [
    DynamicComponentRenderService,
    {
      provide: ENV_CONFIG_AUTH,
      useFactory: (configurationService: ConfigurationService) => {
        let appConfigInfo = configurationService.getAppConfig();
        return appConfigInfo;
      },
      deps: [ConfigurationService],
    },
    {
      provide: DYNAMIC_RENDER_SERVICE,
      useExisting: DynamicComponentRenderService,
    },
  ],
})
export class TransmitModule {}
