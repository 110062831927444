import { Injectable } from '@angular/core';
import { ReferencesImagFlame } from '../constants/references-image-flame';

@Injectable({
  providedIn: 'root',
})
export class DataImageService {
  constructor() {
    // This is intentional
  }

  public getDataImage(image: string | undefined): string {
    let imageAlertFlame = ReferencesImagFlame.default;
    switch (image) {
      case 'ic_phone':
      case 'ic_phone_only':
        imageAlertFlame = ReferencesImagFlame.ic_phone;
        break;
      case 'ic_alert':
        imageAlertFlame = ReferencesImagFlame.default;
        break;
      case 'ic_cloud':
        imageAlertFlame = ReferencesImagFlame.ic_cloud;
        break;
      case 'ic_card':
        imageAlertFlame = ReferencesImagFlame.ic_card;
        break;
      case 'ic_tarjeta':
        imageAlertFlame = ReferencesImagFlame.ic_tarjeta;
        break;
      case '':
        imageAlertFlame = ReferencesImagFlame.default;
        break;
      default:
        imageAlertFlame = ReferencesImagFlame.default;
        break;
    }

    return imageAlertFlame;
  }
}
