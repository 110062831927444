import {
  IBiometricData,
  IDefaultModule,
  IDigitalBranchFeatures,
  IFullBiometricData,
  IFullINEData,
  IINEData,
} from 'src/app/shared/models/interfaces';
import {
  UserProfile,
  IIdentificationRequest,
  IDataDetails,
  IContactDetails,
} from './interfaces';
import {
  IGeolocatinInfo,
  IRsa,
  IWifiNetworkData,
} from '../../expediente/models/interfaces';
import { formatGeolocationHeader } from 'src/app/shared/helpers/utils/shared';

export class OcrSDKResponse implements IINEData {
  ocr: string;
  cic: string;
  paternalName: string;
  maternalName: string;
  name: string;
  numberEmision: string;
  cveElector: string;
  curp: string;

  constructor(ocrIneData: any) {
    this.name = ocrIneData.backData.name ?? ocrIneData.frontData.personNameL3;
    this.paternalName =
      ocrIneData.backData.paternalLastName ?? ocrIneData.frontData.personNameL1;
    this.maternalName =
      ocrIneData.backData.maternalLastName ?? ocrIneData.frontData.personNameL2;
    this.curp = ocrIneData.frontData.curp;
    this.ocr = ocrIneData.backData.ocrIdentifier;
    this.cic = ocrIneData.backData.cicIdentifier;
    this.numberEmision = ocrIneData.frontData.registrationID;
    this.cveElector = ocrIneData.frontData.voterID;
  }
}

export class FingerprintResponse implements IBiometricData {
  type: number = 2;
  width: string;
  high: string;
  firstEncodeData: string;
  secondEncodeData: string;
  biometricKey: string;
  format: string;

  constructor(fingerprintData: any) {
    const firstEncodeData = this.getMinutiae(
      fingerprintData.fingerCaptureData,
      'right'
    );
    const secondEncodeData = this.getMinutiae(
      fingerprintData.fingerCaptureData,
      'left'
    );
    this.firstEncodeData = firstEncodeData?.templates?.WSQ ?? null;
    this.secondEncodeData = secondEncodeData.templates?.WSQ;
    this.width = secondEncodeData.width;
    this.high = secondEncodeData.height;
    this.biometricKey = 'FINGERPRINT';
    this.format = '2';
  }

  getMinutiae(fingerCaptureData: any, handSide: any) {
    const validFingerName = 'index';
    return fingerCaptureData.find(
      (finger: any) =>
        finger.fingerName.toLowerCase() === validFingerName &&
        finger.hand.toLowerCase() === handSide
    );
  }
}

export class FaceResponse implements IFullBiometricData {
  type: number = 2;
  width: string;
  high: string;
  firstEncodeData: string;
  secondEncodeData = null;
  biometricKey: string = 'FACE';
  spoofScore: number;
  format: string = '1';

  constructor(faceData: any) {
    this.firstEncodeData = faceData?.faceCaptureData.templates?.JPEG ?? null;
    this.width = faceData?.faceCaptureData.width ?? '0';
    this.high = faceData?.faceCaptureData.height ?? '0';
    this.spoofScore = faceData?.faceCaptureData.spoofScore ?? 0;
  }
}

export class IdentificationRequest implements IIdentificationRequest {
  name: string;
  paternalName: string;
  maternalName: string;
  birthDate: string;
  curp: string;

  constructor(idInputs: any) {
    this.name = idInputs.nombre;
    this.paternalName = idInputs.apellido_paterno;
    this.maternalName = idInputs.apellido_materno;
    this.curp = idInputs.curp;
    this.birthDate = idInputs.fecha_nacimiento;
  }
}

export class IdentificationProfile implements Partial<UserProfile> {
  dataDetails: IDataDetails;
  contactDetails: IContactDetails = <IContactDetails>{};
  biometricDetails!: IBiometricData;

  constructor(partyId: string, idData: any, idType: string) {
    idData = {
      ...idData,
      nombre: idData.nombre.toUpperCase().replace('Ñ', 'N'),
      apellido_paterno: idData.apellido_paterno.toUpperCase().replace('Ñ', 'N'),
      apellido_materno: idData.apellido_materno.toUpperCase().replace('Ñ', 'N'),
    };

    this.dataDetails = <IDataDetails>{
      partyId: partyId,
      name: idData.nombre,
      paternalName: idData.apellido_paterno,
      maternalName: idData.apellido_materno,
      curp: idData.curp,
      identificationCardData: <IINEData>{
        cic: null,
        ocr: null,
        numberEmision: null,
        cveElector: null,
      },
    };

    this.dataDetails.identificationCardData.cic = idData.cic;
    this.dataDetails.identificationCardData.cveElector = idData.clave_elector;
  }
}

export class FullINEData implements IFullINEData {
  name: string;
  paternalName: string;
  maternalName: string;
  curp: string;
  birthdate: string | null;
  ocr: string | null;
  cic: string | null;
  numberEmision: string | null;
  cveElector: string | null;

  constructor(idInputs: any) {
    this.name = idInputs.nombre;
    this.paternalName = idInputs.apellido_paterno;
    this.maternalName = idInputs.apellido_materno;
    this.curp = idInputs.curp;
    this.birthdate = idInputs.fecha_nacimiento;
    this.ocr = idInputs.ocr;
    this.cic = idInputs.cic;
    this.numberEmision = idInputs.numero_emision_credencial;
    this.cveElector = idInputs.clave_elector;
  }
}
export class GeolocationInfo implements IGeolocatinInfo {
  Altitude: string | null;
  AltitudeAccuracy: string | null;
  Heading: string | null;
  HorizontalAccuracy: string | null;
  Latitude: string | null;
  Longitude: string | null;
  Speed: string | null;
  Status: string | null;
  Timestamp: string | null;

  constructor(geolocationInfo: Partial<IGeolocatinInfo>) {
    this.Longitude = formatGeolocationHeader(geolocationInfo?.Longitude);
    this.HorizontalAccuracy = formatGeolocationHeader(
      geolocationInfo?.HorizontalAccuracy
    );
    this.Latitude = formatGeolocationHeader(geolocationInfo?.Latitude);
    this.Altitude = geolocationInfo?.Altitude ?? null;
    this.AltitudeAccuracy = geolocationInfo?.AltitudeAccuracy ?? null;
    this.Heading = geolocationInfo?.Heading ?? null;
    this.Speed = geolocationInfo?.Speed ?? null;
    this.Status = geolocationInfo?.Status ?? null;
    this.Timestamp = geolocationInfo?.Timestamp ?? null;
  }
}
export class WifiNetworkData implements IWifiNetworkData {
  BBSID: string | null;
  CellTowerId: string | null;
  LocationAreaCode: string | null;
  LocationErrorCode: string | null;
  SSID: string | null;
  SignalStrength: string | null;
  StationName: string | null;
  WiFiMacAddress: string | null;
  constructor(wifiNetworkData: Partial<IWifiNetworkData>) {
    this.BBSID = wifiNetworkData.BBSID ?? null;
    this.CellTowerId = wifiNetworkData.CellTowerId ?? null;
    this.LocationAreaCode = wifiNetworkData.LocationAreaCode ?? null;
    this.LocationErrorCode = wifiNetworkData.LocationErrorCode ?? null;
    this.SSID = wifiNetworkData.SSID ?? null;
    this.SignalStrength = wifiNetworkData.SignalStrength ?? null;
    this.StationName = wifiNetworkData.StationName ?? null;
    this.WiFiMacAddress = wifiNetworkData.WiFiMacAddress ?? null;
  }
}

export class RSAData implements IRsa {
  AdvertiserId: string | null;
  CellTowerId: string | null;
  Compromised: string | null;
  DeviceModel: string | null;
  DeviceName: string | null;
  DeviceSystemName: string | null;
  DeviceSystemVersion: string | null;
  Emulator: string | null;
  GeoLocationInfo: IGeolocatinInfo;
  HardwareID: string | null;
  Languages: string | null;
  LocationAreaCode: string | null;
  MCC: string | null;
  MNC: string | null;
  MultitaskingSupported: boolean;
  OS_ID: string | null;
  PhoneNumber: string | null;
  RSA_ApplicationKey: string | null;
  SDK_VERSION: string | null;
  SIM_ID: string | null;
  ScreenSize: string | null;
  TIMESTAMP: string | null;
  WiFiNetworksData: IWifiNetworkData;

  constructor(rsa: any) {
    this.AdvertiserId = rsa.AdvertiserId ?? null;
    this.CellTowerId = rsa.CellTowerId ?? null;
    this.Compromised = rsa.Compromised ?? null;
    this.DeviceModel = rsa.DeviceModel ?? null;
    this.DeviceName = rsa.DeviceName ?? null;
    this.DeviceSystemName = rsa.DeviceSystemName ?? null;
    this.DeviceSystemVersion = rsa.DeviceSystemVersion ?? null;
    this.Emulator = rsa.Emulator ?? null;
    this.GeoLocationInfo = new GeolocationInfo(rsa.GeoLocationInfo[0]);
    this.HardwareID = rsa.HardwareID ?? null;
    this.Languages = rsa.Languages ?? null;
    this.LocationAreaCode = rsa.LocationAreaCode ?? null;
    this.MCC = rsa.MCC ?? null;
    this.MNC = rsa.MNC ?? null;
    this.MultitaskingSupported = rsa.MultitaskingSupported ?? false;
    this.OS_ID = rsa.OS_ID ?? null;
    this.PhoneNumber = rsa.PhoneNumber ?? null;
    this.RSA_ApplicationKey = rsa.RSA_ApplicationKey ?? null;
    this.SDK_VERSION = rsa.SDK_VERSION ?? null;
    this.SIM_ID = rsa.SIM_ID ?? null;
    this.ScreenSize = rsa.ScreenSize ?? null;
    this.TIMESTAMP = rsa.TIMESTAMP ?? null;
    this.WiFiNetworksData = new WifiNetworkData(rsa.WiFiNetworksData);
  }
}
export class DigitalBranchFeatures implements IDigitalBranchFeatures {
  private: IDefaultModule;
  expediente: IDefaultModule;
  constancias: IDefaultModule;
  faceId: IDefaultModule;

  constructor(digitalBranch: any) {
    this.private = digitalBranch.features?.private ?? <IDefaultModule>{};
    this.expediente = digitalBranch.features?.expediente ?? <IDefaultModule>{};
    this.constancias =
      digitalBranch.features?.constancias ?? <IDefaultModule>{};
    this.faceId = digitalBranch.features?.faceId ?? <IDefaultModule>{};
  }
}
