export const downloadZipFile = (base64: string, name: string) => {
  const byteArray = new Uint8Array(
    atob(base64)
      .split('')
      .map(char => char.charCodeAt(0))
  );
  const blob = new Blob([byteArray], {
    type: 'application/x-zip-compressed',
  });
  const url = window.URL.createObjectURL(blob);

  let a = document.createElement('a');
  document.body.append(a);
  a.href = url;
  a.download = String(name);
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
};
