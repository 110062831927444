import { Component, OnInit } from '@angular/core';
import { DisplayInformationTextModel } from '../../models/display-information-text.model';
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-display-information-page',
  templateUrl: './display-information-page.component.html',
})
export class DisplayInformationPageComponent implements OnInit {
  /**
   * @var data
   * it's variable for to data input
   */
  public data: any;

  /**
   * @var templateText
   * it's variable for to data input
   */
  public templateText!: DisplayInformationTextModel;

  constructor() {}
  /**
   * @method ngOnInit  return void value
   * should init component and set data to element
   */
  ngOnInit(): void {
    this.templateText = this.data;
    this.data.closeEventDialog.subscribe(() => {
      this.data.transmitContinue(-1);
    });
  }

  /**
   * @method continueEvent return void value
   * should callback to emit continueEvent.
   * send taggin data
   */
  public continueEvent(): void {
    this.data.transmitContinue(-1);
    this.data.closeDialog();
  }

  /**
   * Method to replace special characters, replace spaces with hyphens
   * replace accents with your equivalent character and transform the text into lower case.
   * @param text
   * @private
   */
  private replaceCharactersAndSpaces(text: string): string {
    text = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    text = text.replace(/[^a-zA-Z0-9 ]/g, '');
    text = text.toLowerCase();
    return text.replace(/ /g, '_');
  }
}
