import { default as data } from '../../data/copies/copies.json';
import { default as fingerData } from '../../data/mocks/fingerprints.json';
import { default as rsaData } from '../../data/mocks/rsa.json';
import { default as ocrData } from '../../data/mocks/ocr.json';
import { default as accountsProfileData } from '../../data/mocks/accountsProfile.json';
import { default as faceData } from '../../data/mocks/faceId.json';
import { default as digitalBranchData } from '../../data/mocks/digital-branch.json';
import { default as versionInfo } from '../../data/mocks/versionInfo.json';
import { default as telmexInfo } from '../../data/mocks/telmex.json';

export const svColors = {
  white: 'FFFFFF',
  red: 'EC0000',
  secondary: '63B468',
  gray60: '999999',
  light: 'D9D9D9',
  nero: '191919',
  blackColor: '000000',
  darkGray: '4C4C4C',
  disabledGray: '767676',
  primarySucess: '008437',
  darkCharcoal: '333333',
  veryDarkGray: '595959',
  grayLight: 'F2F2F2',
  zambezi: '5F5F5F',
} as const;

export const svHEXColors = {
  white: '#FFFFFF',
  red: '#EC0000',
  secondary: '#63B468',
  gray60: '#999999',
  light: '#D9D9D9',
  nero: '#191919',
  blackColor: '#000000',
  darkGray: '#4C4C4C',
  disabledGray: '#767676',
  primarySucess: '#008437',
  darkCharcoal: '#333333',
  veryDarkGray: '#595959',
  grayLight: '#F2F2F2',
  zambezi: '#5F5F5F',
} as const;

export const svStyles = {
  displayBlock: 'block',
  displayFlex: 'flex',
  regularWeight: '400',
  boldWeight: '700',
  spaceAround: 'space-around',
  spaceEvenly: 'space-evenly',
  baseline: 'baseline',
  center: 'center',
  left: 'left',
  right: 'right',
  start: 'flex-start',
  borderNone: 'rgb(0, 0, 0) none 0px',
} as const;

export const svFonts = {
  santanderTextRegular: 'SantanderTextRegular, sans-serif',
  santanderTextLight: 'SantanderTextLight, sans-serif',
  santanderTextBold: 'SantanderTextBold, sans-serif',
  santanderHeadlineRegular: 'SantanderHeadlineRegular, sans-serif',
  santanderHeadlineBold: 'SantanderHeadlineBold, sans-serif',
  santanderMicrotextBd: 'SantanderMicrotextBd, sans-serif',
  santanderMicrotextBdIt: 'SantanderMicrotextBdIt, sans-serif',
  santanderMicrotextIt: 'SantanderMicrotextIt, sans-serif',
  santanderMicrotextLt: 'SantanderMicrotextLt, sans-serif',
  santanderMicrotextLtIt: 'SantanderMicrotextLtIt, sans-serif',
  santanderMicrotext: 'SantanderMicrotext, sans-serif',
  openSansBold: 'OpenSansBold, sans-serif',
  openSansBoldItalic: 'OpenSansBoldItalic, sans-serif',
  openSansItalic: 'OpenSansItalic, sans-serif',
  openSansLight: 'OpenSansLight, sans-serif',
  openSansLightItalic: 'OpenSansLightItalic, sans-serif',
  openSansRegular: 'OpenSansRegular, sans-serif',
  openSansSemiBold: 'OpenSansSemiBold, sans-serif',
  openSansSemiBoldItalic: 'OpenSansSemiBoldItalic, sans-serif',
};

export const devices = {
  android: 'Android',
  iphone: 'Iphone',
  ios: 'IOS',
  web: 'Web',
  movil: 'Movil',
  desktop: 'Desktop',
  desconocido: 'Desconocido',
};

export const gtm = {
  versionAppName: 'unknown-app',
  typeBiometric: {
    face: 'faceid',
    finger: 'huellas',
  },
  event: {
    page: 'PageView',
    interaction: 'sucursal_virtual',
  },
  pageView: {
    tipoSitio: 'Publico',
    idiomaPagina: 'Espanol',
    canalBanco: 'SucursalVirtual',
    versionApp: '',
    userId: '',
  },
  category: {
    constancias: 'constancias_fiscales',
    sucVirtual: 'sucursal-virtual',
    miExpediente: 'mi_expediente',
    ine: 'captura_ine',
    confirmaDatos: 'confirma_datos',
    capturaHuellas: 'captura_huellas',
    capturaFacial: 'captura_rostro',
    actualizacionDatos: 'datos_contacto',
    validaTusDatos: 'valida_tus_datos',
    contrasena: 'creacion_contrasena',
    comprobante: 'comprobante',
    error: 'error',
    otp: 'otp',
    datosPersonales: 'datos_personales',
    expedientes: 'mi_expediente',
  },
  action: {
    account: 'cuenta_o_contrato',
    successDownload: 'descarga_exitosa',
    selectYear: 'anio',
    bienvenida: 'bienvenida',
    inicio: 'inicio',
    actualizacion: 'actualizacion_datos',
    capturar: 'momento_de_capturar',
    geolocalizacion: 'activa_geolocalizacion',
    camara: 'activa_camara',
    general: 'general',
    actualizaDatosContacto: 'actualiza_tus_datos_de_contacto',
    otp: 'otp',
    clienteDigital: 'cliente_digital',
    clienteNoDigital: 'clientes_no_digital',
    validacionDatos: 'validacion_datos',
    verificacion: 'verificacion',
    actualizar: 'actualizar_domicilio',
    confirmar: 'confirmar_datos',
    continuar: 'continuar',
    expediente: 'mi_expediente',
    constancias: 'Constancias-fiscales',
    cargaComprobante: 'carga_de_comprobante',
    comprobante: 'comprobante',
    seleccionComp: 'seleccion_comprobante',
    datosDomicilio: 'datos_domicilio',
    domicilioRegistrado: 'domicilio_registrado',
    tipoDeIdentificacion: 'tipo_de_identificacion',
    companiaTelefonica: 'compania_telefonica',
    popup: 'popup',
  },
  page: {
    bienvenido: 'sucursal-virtual/actualizacion_datos',
    escaneaTuIne: 'sucursal-virtual/paso_1/captura_ine',
    identificacion: 'sucursal-virtual/paso_2/datos_personales',
    actualizar: 'sucursal-virtual/paso_3/datos_contacto',
    capturaTusHuellas: 'sucursal-virtual/paso_4/captura_huellas',
    comprobante: 'sucursal-virtual/comprobante',
    capturaRostro: 'sucursal-virtual/captura_rostro',
    loaders: {
      capturaIne: 'sucursal-virtual/paso_1/captura_ine/espera',
      capturaHuellas: 'sucursal-virtual/paso_4/captura_huellas/espera',
      capturaRostro: 'sucursal-virtual/paso_4/captura_rostro/espera',
    },
    constancias: 'sucursal-virtual/constancias-fiscales',
    errorConstancias:
      'sucursal-virtual/constancias-fiscales/usuario-nuevo-sin-constancia',
    constanciasSupertoken: 'sucursal-virtual/constancias-fiscales/supertoken',
    modales: {
      dispositivoNoValido: 'Home/actualizacion-datos/dispositivo-invalido',
      mayorDe65: 'sucursal-digital/paso_2/mayor_65_anios',
      otp: 'sucursal-virtual/otp',
      monoproducto: 'sucursal-virtual/monoproducto_n2',
    },
    error: {
      generico: 'generico',
      bloqueo: 'bloqueo_de_seguridad',
      mayor65: 'cliente_mayor_65',
      monoproducto: 'monoproducto_n2',
      noCliente: 'no_cliente_o_datos_incorrectos',
      rsaDeny: 'rsa_acude_a_sucursal',
      otp: 'otp_invalido',
      retry: 'volver_a_intentar',
      intentosExcedidos: 'excedio_intentos',
    },
    consulta: 'sucursal-virtual/consulta-informacion',
    expediente: 'sucursal-virtual/mi-expediente',
    domicilioRegistrado: 'sucursal-virtual/mi-expediente/domicilio-registrado',
    cargaComprobante: 'sucursal-virtual/mi-expediente/carga-de-comprobante',
    seleccionComprobante:
      'sucursal-virtual/mi-expediente/seleccion-de-comprobante',
    comprobanteCargado: 'sucursal-virtual/mi-expediente/comprobante',
  },
  label: {
    continuar: 'continuar',
    requestOtp: 'solicitar otro código',
    comprobante: 'comparte el comprobante',
    actualizar: 'actualizar',
    selectAccount: 'selecciona_una_cuenta',
    selectYear: 'seleccionar_anio',
    downloadPDF: 'descargar',
    closeToast: 'cerrar_toast',
    confirmar: 'confirmar',
    consultar: 'consultar',
    compartir: 'compartir',
    cargarComprobante: 'Carga tu comprobante',
    eliminar: 'eliminar',
    huellasDigitales: 'huellas_digitales',
    capturaFacial: 'reconocimiento_facial',
  },
};

export const guards = {
  ORIGIN: 'ORIGIN',
  USER: 'USER',
  USER_TYPE: {
    GUEST: 'GUEST',
    CLIENT: 'CLIENT',
  },
  SOURCE: {
    MOBILE: 'MOBILE',
  },
};

export const codeInputStates = {
  invalidValue: 'invalid-input',
  hasValue: 'has-value',
  showValue: 'show-value',
};

export const taxCertificatesParams = {
  codeWeb: '4',
  codeMobil: '5',
};

export const copies = data ?? {};
export const fingerprints = fingerData ?? {};
export const faceId = faceData ?? {};
export const rsa = rsaData ?? {};
export const ocr = ocrData ?? {};
export const scrollHeight = document.body.scrollHeight;
export const accountsProfile = accountsProfileData ?? {};
export const digitalBranchMock = digitalBranchData ?? {};
export const versionInfoMock = versionInfo ?? {};
export const telmexMock = telmexInfo ?? {};
