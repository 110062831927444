import { DynamicComponentRenderAbstract } from 'ngx-transmit-wrapper';
import { LoaderTemplateComponent } from '../templates/loader-template/loader-template.component';
import { DisplayInformationPageComponent } from '../pages/display-information-page/display-information-page.component';
import { DialogConfirmationPageComponent } from '../pages/dialog-confirmation-page/dialog-confirmation-page.component';
import { UniversalDefaultPageComponent } from '../pages/universal-default-page/universal-default-page.component';
import { AdComponent } from './ad-component';

export class DynamicComponentRenderService extends DynamicComponentRenderAbstract {
  constructor() {
    super();
  }

  public loadComponent(template_id: string, data: any) {
    return new AdComponent(UniversalDefaultPageComponent, data);
  }

  public loadLoaderComponent() {
    return LoaderTemplateComponent;
  }

  public loadConfirmationComponent() {
    return DialogConfirmationPageComponent;
  }

  public loadDisplayInfoComponent() {
    return DisplayInformationPageComponent;
  }
}
