import { ActionReducerMap } from '@ngrx/store';
import { uiReducer } from './store/reducers/ui.reducer';
import { IUiState } from './store/models/interfaces';

export interface AppState {
  ui: IUiState;
}

export const appReducers: ActionReducerMap<AppState> = {
  ui: uiReducer,
  //Agregar Reducers para los demas states
};
