import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RsaService {
  private deviceId: string = '';
  private geolocation: string = '';

  constructor() {}

  get getDeviceId(): string {
    return this.deviceId;
  }

  setDeviceId(deviceId: string) {
    this.deviceId = deviceId;
  }

  get getGeolocation(): string {
    return this.geolocation;
  }

  setGeolocation(geolocation: string) {
    this.geolocation = geolocation;
  }
}
