import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { gtm } from './shared/helpers/utils/shared';
import { GtmService } from './core/services/gtm/gtm.service';
import { DigitalBranchService } from './shared/services/digital-branch/digital-branch.service';
import { DigitalBranchFeatures } from './modules/update-profile/models/models';
import { DeviceCommunicationService } from './shared/services/devices/device-communication.service';
import { Store } from '@ngrx/store';
import { delay, timeout } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'sucursal-virtual';

  constructor(
    private router: Router,
    private gtmService: GtmService,
    private deviceCommunicationService: DeviceCommunicationService,
    private digitalBranchService: DigitalBranchService,
    private store: Store
  ) {
    localStorage.setItem('SVAppVersion', environment.appVersion);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const currentUrl = this.gtmService.getCurrentUrl(event.url);
        let gtmData: any = {
          page: currentUrl,
        };
        if (sessionStorage.getItem('typeBiometric')) {
          gtmData['flujo'] = sessionStorage.getItem('typeBiometric') || '';
        }
        this.gtmService.pushEvent(gtm.event.page, gtmData);
      }
    });
  }
  ngOnInit(): void {
    this.getDigitalBranch();
  }

  getDigitalBranch = () => {
    this.deviceCommunicationService.getVersionInfo();
    // this.deviceCommunicationService.getDigitalBranchConfig();

    this.deviceCommunicationService.getResponse().subscribe((response: any) => {
      if (response.event === 'fetchDigitalBranchConfiguration') {
        const digitalBranch = new DigitalBranchFeatures(response.message);
        this.digitalBranchService.setBillStatus(
          digitalBranch.expediente.isEnabled ?? false
        );
        this.digitalBranchService.setTaxCertificateStatus(
          digitalBranch.constancias.isEnabled ?? false
        );
        this.digitalBranchService.setFaceIdFlag(
          digitalBranch.faceId.isEnabled ?? false
        );
      } else if (response.event === 'VERSIONINFO') {
        sessionStorage.setItem(
          'VERSIONINFO',
          response.message?.versionName ?? 0
        );
        this.digitalBranchService.setIsValidVersion(
          response.message?.versionCode ?? 0
        );

        this.digitalBranchService.setVersionAppName(
          response.message?.versionName
        );
        this.digitalBranchService.setFaceIdFlag(true);
      }
    });
    delay(1500);
    if (this.digitalBranchService.getVersionAppName() !== gtm.versionAppName) {
      this.deviceCommunicationService.getVersionInfo();
    }
  };
}
