import { RSAData } from 'src/app/modules/update-profile/models/models';
import {
  IGlobalModals,
  IInformativeModals,
  ModalData,
} from '../../../models/interfaces';
import { copies } from './constants';

export const hidePhoneNumber = (phoneNumber: string) =>
  `*${phoneNumber?.slice(-4)}`;

export const hideEmail = (email: string) =>
  email.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, '$1***@$2') || '';

export const getInstructionsForUpdateProfileOTP = (
  phoneNumber: string,
  email: string,
  originalInstructions: string
) =>
  originalInstructions
    .replace(copies.otp.phoneNumber, hidePhoneNumber(phoneNumber))
    .replace(copies.otp.email, hideEmail(email));

export const getAuthenticationModalError = (errorType: string): ModalData => {
  if ('invalidDevice' !== errorType) {
    return {
      title: copies.modal.acudeSucursal.title,
      description: copies.modal.acudeSucursal.description,
      titleButton: copies.modal.acudeSucursal.titleButton,
      backButton: copies.modal.buttons.cerrar,
    };
  }
  return {
    title: copies.modal.dispositivoNoValido.title,
    description: copies.modal.dispositivoNoValido.description,
    titleButton: copies.modal.dispositivoNoValido.titleButton,
    backButton: copies.modal.dispositivoNoValido.backButton,
  };
};

export const getInformativeModals = (): IInformativeModals => ({
  validateYourData: {
    title: copies.modal.validaTusDatos.title,
    description: copies.modal.validaTusDatos.description,
    titleButton: copies.modal.validaTusDatos.titleButton,
    backButton: copies.modal.validaTusDatos.backButton,
    mainButton: copies.modal.validaTusDatos.mainButton,
    image: copies.modal.validaTusDatos.img,
  },
});

export const getGlobalModalsContent = (): IGlobalModals => ({
  cameraConfig: {
    title: copies.modal.camara.title,
    description: copies.modal.camara.description,
  },
  geolocalizationConfig: {
    title: copies.modal.geolocalization.title,
    description: copies.modal.geolocalization.description,
  },
  buttonsConfig: {
    mainColor: 'primary',
    mainText: copies.modal.buttons.configurationButton,
    secondaryButton: true,
    secondaryColor: 'secondary',
    secondaryText: copies.modal.buttons.cancelar,
  },
});

export const uppercaseText = function (value: any) {
  if (typeof value === 'string') {
    return value.toUpperCase();
  } else {
    return value;
  }
};

export const getBirthDate = function (dateOfBirth: string) {
  try {
    const anioCurp = dateOfBirth.substring(6);
    const anioactual = new Date().getFullYear() % 100;
    const mes = dateOfBirth.substring(3, 5);
    const dia = dateOfBirth.substring(0, 2);
    const siglo = parseInt(anioCurp) <= anioactual ? '20' : '19';
    const anioFull = parseInt(siglo + anioCurp);

    const birthDate = `${anioFull}-${mes}-${dia}`;

    return birthDate;
  } catch (error) {
    return '';
  }
};

export const getBirthDateFromCurp = function (dateOfBirth: string) {
  try {
    const anioCurp = dateOfBirth.substring(0, 2);
    const anioactual = new Date().getFullYear() % 100;
    const mes = dateOfBirth.substring(2, 4);
    const dia = dateOfBirth.substring(4).padStart(2, '0');
    const siglo = parseInt(anioCurp) <= anioactual ? '20' : '19';
    const anioFull = parseInt(siglo + anioCurp);

    const birthDate = `${anioFull}-${mes}-${dia}`;

    return birthDate;
  } catch (error) {
    return '';
  }
};

export const maskEmail = function (email: string): string {
  let emailMasked = '';
  if (email) {
    const arroba = email.indexOf('@');
    const inicio = email.substring(0, 2);
    const asterisco = '*'.repeat(arroba - 2);
    emailMasked = `${inicio}${asterisco}${email.substring(arroba)}`;
  }

  return emailMasked;
};

export const maskPhoneNumber = function (phone: string): string {
  let numberMasked = '';
  if (phone) {
    const phoneNumber = phone.replace(/[-\s]/g, '');
    const numberLength = phoneNumber.length;
    const asterisco = '*'.repeat(numberLength - 4);
    const lastNumbers = phoneNumber.substring(numberLength - 4);
    numberMasked = `${asterisco}${lastNumbers}`;
  }

  return numberMasked;
};

export const formatPrivacyNotice = function (input: string): string {
  let formated = input;
  // regex para seleccionar los textos que deben estar en negritas
  const regexBullets = /(\t+)(\?)/gm;
  const regexTitles = /^(\d\s)(.*)/gm;
  const regexSubtitles = /^\d+(\.\d+?\.)([^:.]+)(:|.-)/gm;
  const regexSubtitlesTwo = /^(\s)*(\d.)?( )(?!“)/gm;
  const regexSubtitlesThree = /^(\s)*(\d*.)(\d.)( )(?!“)/gm;
  const regexAvisoPrivacidad = /^(AVISO DE PRIVACIDAD)/gm;

  formated = formated.replace(regexBullets, '<b>$1&bull;</b>');
  formated = formated.replace(regexTitles, '<b>$&</b>');
  formated = formated.replace(regexSubtitles, '<b>$&</b>');
  formated = formated.replace(regexSubtitlesTwo, '<b>$&</b>');
  formated = formated.replace(regexSubtitlesThree, '<b>$&</b>');
  formated = formated.replace(regexAvisoPrivacidad, '<b>$&</b>');

  return formated;
};

export const formatRSAHeaders = (
  rsa: RSAData
): { deviceId: string; geolocalization: string } => {
  const geolocationInfo = rsa.GeoLocationInfo;
  const geolocalization = `${geolocationInfo.Latitude};${geolocationInfo.Longitude};${geolocationInfo.HorizontalAccuracy};${geolocationInfo.Timestamp}`;
  const deviceId = `DeviceId=${rsa.HardwareID};TimeStamp=${rsa.TIMESTAMP};DeviceModel=${rsa.DeviceModel};Languages=${rsa.Languages};Mobile Country Code=${rsa.MCC};DeviceSystemVersion=${rsa.DeviceSystemVersion};DeviceName=${rsa.DeviceName};WiFiNetworkData={BBSID=${rsa.WiFiNetworksData.BBSID};SignalStrength=${rsa.WiFiNetworksData.SignalStrength};SSID=${rsa.WiFiNetworksData.SSID};};MNC=${rsa.MNC};MultiTaskingSupported=${rsa.MultitaskingSupported};ScreenSize=${rsa.ScreenSize};DeviceSystemName=${rsa.DeviceSystemName};RSAApplicationKey=${rsa.RSA_ApplicationKey};SDKVersion=${rsa.SDK_VERSION};OSID=${rsa.OS_ID};SIM_ID=${rsa.SIM_ID};PhoneNumber=${rsa.PhoneNumber};WiFiMacAddress=${rsa.AdvertiserId};CellTowerID=${rsa.CellTowerId};LocationAreaCode=${rsa.LocationAreaCode};Compromised=${rsa.Compromised};Emulator=${rsa.Emulator};`;
  return { deviceId: deviceId, geolocalization: geolocalization };
};

export const allReplace = (originalString: string, replacemnts: string) => {
  replacemnts.split('').forEach(character => {
    originalString = originalString.replace(new RegExp(character, 'g'), '');
  });
  return originalString;
};
export const cleanNoNumericChars = (original: string): string =>
  allReplace(original, 'ABCDEFGHIJKLMNÑOPQRSTUVWXYZ');

export const cleanNoLetterChars = (original: string): string =>
  allReplace(original, '0123456789');

export const formatCURP = (curp: string) => {
  curp = uppercaseText(curp);
  const firstPart = cleanNoLetterChars(curp.slice(0, 4));
  const secondPart = cleanNoNumericChars(curp.slice(4, 10));
  const thirdPart = cleanNoLetterChars(curp.slice(10, 16));
  const lastPart = cleanNoNumericChars(curp.slice(16));
  return firstPart + secondPart + thirdPart + lastPart;
};

export const formatGTMValue = (value: string) =>
  value.toLocaleLowerCase().replace(' ', '_');

export const formatGeolocationHeader = (value: unknown) => {
  if (value) {
    const castedValue = <string>value;
    return castedValue.length > 8 ? castedValue.slice(0, 8) : castedValue;
  }
  return null;
};
export const replaceString = (
  cad: string,
  search: string,
  value: string
): string => cad.split(search).join(value);
