import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { DigitalBranchService } from 'src/app/shared/services/digital-branch/digital-branch.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FeatureGuard implements CanActivate {
  constructor(
    private router: Router,
    private digitalBranchService: DigitalBranchService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const featureFlag: 'constanciasFeatures' | 'expedientesFeatures' =
      route.data['featureFlag'];

    if (this.digitalBranchService.getFeatureFlag(featureFlag)) {
      return true;
    }
    return this.reject();
  }

  reject() {
    this.router.navigate(['/actualizar-datos/']);
    return false;
  }
}
