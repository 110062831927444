import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FeatureGuard } from './core/guards/feature-guard/feature-guard.guard';

export const routes: Routes = [
  {
    path: 'actualizar-datos',
    loadChildren: () =>
      import('./modules/update-profile/update-profile.module').then(
        module => module.UpdateProfileModule
      ),
  },
  {
    path: 'constancia-fiscal',
    canActivate: [FeatureGuard],
    data: { featureFlag: 'constanciasFeatures' },
    loadChildren: () =>
      import('./modules/constancia-fiscal/constancia-fiscal.module').then(
        module => module.ConstanciaFiscalModule
      ),
  },
  {
    path: 'expediente',
    canActivate: [FeatureGuard],
    data: { featureFlag: 'expedientesFeatures' },
    loadChildren: () =>
      import('./modules/expediente/expediente.module').then(
        module => module.ExpedienteModule
      ),
  },
  {
    path: 'web',
    loadChildren: () =>
      import('./modules/web/web.module').then(module => module.WebModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/hub/hub.module').then(module => module.HubModule),
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
