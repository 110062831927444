<div
  class="tmit-display-information"
  [ngClass]="templateText.continueText ? 'tmit-btn' : 'tmit-text'"
>
  <flame-empty-state *ngIf="imageData">
    <img
      class="tmit-display-information__image align-self-center"
      src="/assets/images/{{ imageData }}.svg"
      alt=""
    />
  </flame-empty-state>
  <h2 class="tmit-display-information__title">{{ templateText.title }}</h2>
  <p class="tmit-display-information__text">{{ templateText.text }}</p>
  <button
    *ngIf="templateText.continueText"
    (click)="continueEventButton(templateText.continueText)"
    class="align-self-center w-25 tmit-display-information__button"
    flameButton
    color="primary"
  >
    {{ templateText.continueText }}
  </button>
</div>
