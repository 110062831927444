import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DisplayInformationTextModel } from '../../models/display-information-text.model';
import { ENV_CONFIG_AUTH } from 'ngx-transmit-wrapper';
import { DataImageService } from '../../services/data-image.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-display-information-template',
  templateUrl: './display-information-template.component.html',
  styleUrls: ['./display-information-template.component.scss'],
})
export class DisplayInformationTemplateComponent implements OnInit {
  protected imageData!: string;

  @Input() templateText!: DisplayInformationTextModel;
  @Output() continueEvent = new EventEmitter<boolean>();

  constructor(
    @Inject(ENV_CONFIG_AUTH) private environment: any,
    private dataImage: DataImageService
  ) {}

  ngOnInit() {
    this.imageData = this.dataImage.getDataImage(this.templateText.imageModal);
  }
  public continueEventButton(continueText: string) {
    if (continueText === 'Ir a SuperNet') {
      window.location.href = this.environment.supernetURL;
    }

    this.continueEvent.emit(true);
  }
}
